import axios from '@/api/config/interceptor'
import {
    host
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

// 查询代理商列表
export const GetAgentList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMPC/GetAgentList/AgentList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询代理商下 客户列表
export const GetAgentToCustomerList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMApplet/MessageCustomerModel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询客户列表
export const GetCustomerList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMPC/GetCustomerList/CustomerList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询历史流失客户列表
export const GetLostRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMPC/GetCustomerLostRecord/LostRecord`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 报表信息
export const GetReport = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/CRMApplet/GetPlatformReport`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 跟进记录列表
export const GetFollowUpList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMApplet/GetFollowUpList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取客户详情
export const GetCustomerDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/CRMApplet/GetCustomDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取跟进记录
export const getFollowUpList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/CRMApplet/GetFollowUpList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 获取转入记录
export const getChangeInto = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/CRMApplet/GetMessageModel?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}