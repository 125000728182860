<template>
  <div>
    <el-drawer
      title="客户信息"
      :visible.sync="customerDrawer"
      direction="rtl"
      :size="1000"
    >
      <div style="margin: 0px 16px;">
        <el-row
          style="margin-bottom: 8px;font-size: 14px;font-weight: bold;color: #333;"
        >
          <el-col :span="12"
            >公司名称：<span style="font-weight: normal">{{
              customerDetailObj.Name
            }}</span></el-col
          >
          <el-col :span="12"
            >客户状态：<span
              :style="[
                { fontWeight: 'normal' },
                {
                  color:
                    customerDetailObj.AscriptionUserID == 0
                      ? '#0F5FFF'
                      : customerDetailObj.Status == 0 ||
                        customerDetailObj.Status == 1
                      ? '#FF8B17'
                      : customerDetailObj.Status == 2
                      ? '#999999'
                      : '#00E855',
                },
              ]"
              >{{
                customerDetailObj.AscriptionUserID == 0
                  ? "公共客户"
                  : customerDetailObj.Status == 0
                  ? "待跟进"
                  : customerDetailObj.Status == 1
                  ? "跟进中"
                  : customerDetailObj.Status == 2
                  ? "已流失"
                  : "已成交"
              }}</span
            ></el-col
          >
        </el-row>
        <el-row
          style="margin-bottom: 8px;font-size: 14px;font-weight: bold;color: #333;"
        >
          <el-col :span="12"
            >公司地址：<span style="font-weight: normal"
              >{{ customerDetailObj.Province }}{{ customerDetailObj.City
              }}{{ customerDetailObj.County
              }}{{ customerDetailObj.Address }}</span
            ></el-col
          >
          <el-col :span="12"
            >流失次数：<span style="font-weight: normal">{{
              customerDetailObj.LpCount ? customerDetailObj.LpCount : 0
            }}</span></el-col
          >
        </el-row>
        <el-row
          style="margin-bottom: 8px;font-size: 14px;font-weight: bold;color: #333;"
        >
          <el-col :span="12"
            >手机号：<span style="font-weight: normal">{{
              customerDetailObj.ContactsPhone
            }}</span></el-col
          >
          <el-col :span="12"
            >转入次数：<span style="font-weight: normal">{{
              customerDetailObj.ZpCount ? customerDetailObj.ZpCount : 0
            }}</span></el-col
          >
        </el-row>
        <el-row
          style="margin-bottom: 8px;font-size: 14px;font-weight: bold;color: #333;"
        >
          <el-col :span="12"
            >客户信息图片：<span
              v-if="customerDetailObj.ImgUrls"
              class="customerImgNum"
              @click="
                customerDetailObj.ImgUrls.length > 0
                  ? hideImgFileDialog(true, 'Array')
                  : ''
              "
              :style="[
                {
                  cursor: customerDetailObj.ImgUrls.length > 0 ? 'pointer' : '',
                },
              ]"
              >{{
                customerDetailObj.ImgUrls ? customerDetailObj.ImgUrls.length : 0
              }}</span
            >
            <span v-else class="customerImgNum">0</span>
          </el-col>
          <el-col :span="12"
            >添加时间：<span style="font-weight: normal">{{
              customerDetailObj.AddTime
            }}</span></el-col
          >
        </el-row>
        <div class="line"></div>
        <el-table
          :data="changeIntoPagination.list"
          v-loading="changeIntoPagination.loading"
          :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        >
          <el-table-column
            align="center"
            prop="LName"
            label="流失代理"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="ZName"
            label="转入代理"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="msg"
            label="流失原因"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Ltime"
            label="流失时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Ztime"
            label="转入时间"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="display:flex; align-items:center;margin-top: 10px">
          <el-pagination background
            class="pagination"
            @current-change="handleCurrentChange($event, 1)"
            :current-page.sync="changeIntoPagination.page"
            :page-size="changeIntoPagination.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="changeIntoPagination.total"
          >
          </el-pagination>
        </div>
        <div style="margin: 16px 0px;">
          <strong>跟进信息：{{ followUpPagination.total }} 条</strong>
        </div>
        <el-table
          :data="followUpPagination.list"
          v-loading="followUpPagination.loading"
          :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        >
          <el-table-column
            align="center"
            prop="FollowAgentName"
            label="跟进人(代理商姓名)"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Content"
            label="跟进内容"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="FollowTypeName"
            label="跟进方式"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="ImgUrl"
            label="图片"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                :style="[
                  { color: '#0F5FFF' },
                  { 'text-decoration': scope.row.ImgUrl ? 'underline' : '' },
                  { cursor: scope.row.ImgUrl ? 'pointer' : '' },
                ]"
                @click="
                  scope.row.ImgUrl
                    ? hideImgFileDialog(true, scope.row.ImgUrl)
                    : ''
                "
              >
                {{ scope.row.ImgUrl ? "查看" : "-" }}
              </span>
              <el-image
                style="display:none;"
                ref="previewImg"
                :preview-src-list="previewImg"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="FollowupTime"
            label="跟进时间"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="display:flex; align-items:center;margin-top: 10px">
          <el-pagination background
            class="pagination"
            @current-change="handleCurrentChange($event, 2)"
            :current-page.sync="followUpPagination.page"
            :page-size="followUpPagination.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="followUpPagination.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-drawer>

    <el-dialog title="查看照片" :visible.sync="imgFileDialog" width="716px">
      <div style="display:flex;flex-wrap: wrap;">
        <div
          class="imgFilePreview"
          v-for="(imgItem, imgIndex) in detailImgList"
          :key="imgIndex"
        >
          <el-image
            class="imgFile"
            style="width: 200px; height: 150px;"
            :src="imgItem"
            :preview-src-list="detailImgList"
            fit="cover"
          ></el-image>
          <p
            style="text-align:center;padding:10px 35px 0 0;box-sizing:border-box;color:#333333; font-weight: bold;"
          >
            {{ imgIndex < 10 ? "0" + (imgIndex + 1) : imgIndex + 1 }}
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgFileDialog = false">取 消</el-button>
        <el-button type="primary" @click="imgFileDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetCustomerDetail,
  getFollowUpList,
  getChangeInto,
} from "@/api/agentManage/customerManage";
export default {
  data() {
    return {
      customerDrawer: false, // 抽屉框 弹框
      imgFileDialog: false, // 图片 弹框
      cid: "", // 查询详情使用的id
      changeIntoPagination: {
        // 转入信息分页
        page: 1,
        pagesize: 5,
        total: 0,
        loading: false,
        list: [],
      },
      followUpPagination: {
        // 跟进信息分页
        page: 1,
        pagesize: 5,
        total: 0,
        loading: false,
        list: [],
      },
      customerDetailObj: {}, // 客户信息详情
      detailImgList: [],
      previewImg: [], // 跟进记录图片存储
    };
  },
  methods: {
    // 查看客户详情
    customerDetail(item) {
      this.cid = item.CID;
      this.customerDrawer = true;
      this.changeIntoPagination.page = 1;
      this.followUpPagination.page = 1;

      GetCustomerDetail({ cid: this.cid }).then((res) => {
        this.customerDetailObj = res.data;

        this.getChangeInto(this.cid);
        this.getFollowUpInfo(this.cid);
      });
    },

    // 获取转入信息
    getChangeInto(cid) {
      let parmas = {
        pageSize: this.changeIntoPagination.pagesize,
        pageIndex: this.changeIntoPagination.page,
        cid,
      };
      getChangeInto(parmas).then((res) => {
        let { DataList } = res.data;
        this.changeIntoPagination.list = DataList;
        // this.changeIntoPagination.list = DataList.map((item, index) => {
        //   item.index =
        //     (this.changeIntoPagination.page - 1) *
        //       this.changeIntoPagination.pagesize +
        //     index +
        //     1;
        //   return item;
        // });

        this.changeIntoPagination.total = res.data.TotalCount;
        this.changeIntoPagination.loading = false;
      });
    },

    // 获取跟进信息
    getFollowUpInfo(cid) {
      let parmas = {
        pageSize: this.followUpPagination.pagesize,
        pageIndex: this.followUpPagination.page,
        cid,
        userId: 0,
      };
      getFollowUpList(parmas).then((res) => {
        this.followUpPagination.list = res.data.DataList;
        this.followUpPagination.total = res.data.TotalCount;
        this.followUpPagination.loading = false;
      });
    },

    //分页控件页码change事件回调
    handleCurrentChange(e, type) {
      if (type == 1) {
        // 转入信息的分页
        this.changeIntoPagination.loading = true;
        this.changeIntoPagination.page = e;
        this.getChangeInto(this.cid);
      } else {
        // 跟进信息的分页
        this.followUpPagination.loading = true;
        this.followUpPagination.page = e;
        this.getFollowUpInfo(this.cid);
      }
    },

    // 图片弹框
    hideImgFileDialog(flag, type) {
      if (type == "Array") {
        this.imgFileDialog = flag;
        this.detailImgList = [];
        this.detailImgList = this.customerDetailObj.ImgUrls;
      } else {
        this.previewImg = [];
        this.previewImg = [type];
        this.$refs.previewImg.clickHandler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 8px;
}

.customerImgNum {
  font-weight: normal;
  text-decoration: underline;
  color: #0f5fff;
  font-weight: bolder;
}

.imgFilePreview {
  .imgFile {
    margin: 18px 35px 0 0;
  }

  &:nth-child(3n) {
    .imgFile {
      margin-right: 0;
    }
  }
}

/deep/.el-dialog__body {
  padding: 0px 16px 18px !important;
}
</style>
